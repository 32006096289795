import React, { useEffect } from "react";
import sha512 from "js-sha512";

const generateHash = (
  key,
  txnid,
  amount,
  productinfo,
  firstname,
  email,
  salt
) => {
  const hashString = `${key}|${txnid}|${amount}|${productinfo}|${firstname}|${email}|||||||||||${salt}`;
  const hash = sha512(hashString);
  return hash;
};

const PaymentComponent = () => {
  const key = "W7DgWC";
  const txnid = "KALES-TXN-001"; // Generate this dynamically in production
  const amount = "10.00";
  const productinfo = "Kales";
  const firstname = "John";
  const email = "john@example.com";
  const phone = "9995086757";
  const salt = "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDTAS9ZD2FLzuG4EQXp+om3wF2CDp4DDha+pTxjOGUOsrfpHm6bYGzBuNWqQWOJzUgMZzIT2wJYkkIYyetovOkiu8WrvmG9yxkbAWGqcEi3VORILbRrqooQYlO9MrG+tCmAtDsK51gd8jpbBQg9e88RxOzuz+BeM/cDy1J+7uG/ruCdRaowhhcNc9fvISnjs4KhV845+ctYih/m4ujycPq7eDrolEvm5343rIdikZGsqUpgetiTqvbvZe5pLoRmiCYbSQ2uzKi5BzbEYeyiN+iiLbxHTNXl5EBf2xkfUfJYJm1bBq8KJKGN+QdRYbdRJ0Q60la2jWz6jzBkhApjLqYBAgMBAAECggEANbqng9YWwGb9sxEioGHbj568MNHoCn8b64/HwLwrf4Kisgm9wHLdPrcCMUhVQFG1dvMQHV43SrZzfjxsZU81TlLiEsl3ugxtaCMB65SL76L+PiLlPkU+ws1G5XZzLExUBSHfpvchyP5VPbgaUQ2lvLCYZHcqetJgo+ayKwBJG7XFsH+74US9CtAtoeaZRxSMdIeFBnslHx2wiC7Fwap+p7bNZme2YsSxGfJWMsugMjIcsQqJXzL83GFR+7MdRLFTt8H+VLxXQxE71hyLe5P5jHcnjQbV8z2hKuksM53BeHkcqUN5IUJAZvcq31frQpTKa5565e+sDI7wlCyYt3v0AQKBgQDvurWxko5mz2i2O9R99HSbrFR1pkgkaUguhZr0Udo7wxoxABKGxQekJg1Tjx9QYPE+ZKSiLSehjyURWKokZiafXo48Lurpq2FUHI9uTiaI/DRFUOFJ3DqbjBnWh8qn5A4qudlotgbfAejmlvpPrbrHGFc7DeuzweqaDX3zz3r/qQKBgQDhU2JfsF3R4BSTEIEGiifGqhdTdAdhUObjTKyetuuqUaA7CkzNj+jfvCdsMyRnILsCXOwklDjHRcXrl28hDC8UtgPeKNSNuk/ALjKvDCkabQUvOm7wBmq9+iGceW5sOr5ZLJaIxZVEPx9ROf4w/QK/ZhZgEgLjvcBd1vHDjb1KmQKBgHoKD8MFO88k2UakMKmqT86MLTbzXpiCrEdapqaoILP5v5SgV84Z8ajj4jbejm9p2oYL0C/rUtiROaqZ+4uqIRFSnpAOxnIMV/Ds1SuvRzjHQ4jiAr6KCZ/z85dd65IKrBeeIwqGV/shSsJQq77yTzpZrtaWYJ5TIq4oHi2HOzN5AoGAUod8DJhIhMqSPptY5tBLaVR7XrGs0lQTfUVkJG3TO3rSOU24e8IuBWSg+9xvhOZUGLV4OJ2hfjtsFrJMncocpHVWTVSfJZoMA9Pn9H17wJYVxAqe/4MaLaMkFG/VpgaOGZNfP5E+7tQ/4DxhggfprbjykRWHwWqKvtQvkPSlVGECgYEAr/tF2JfF7WZ6ZYr+DvI4LouNfzjWSqimsx+k0U9lRSdO1bCpKiPUyus1Gy5os/Drs47Cpqe6CBkTjj460DTk4E7VGTot6W8uJPS2qpSyo5aIIdWQZdAk0MlAMrN+nEJY17e5OOb7yJMUyUe5tvNkERMiNKV/jFtp8P1BZePLZNs=";

  const hash = generateHash(
    key,
    txnid,
    amount,
    productinfo,
    firstname,
    email,
    salt
  );

  const payload = {
    key,
    txnid,
    amount,
    productinfo,
    firstname,
    email,
    phone,
    surl: "http://localhost:5000/success",
    furl: "http://localhost:5000/failure",
    hash,
    service_provider: "payu_paisa",
  };

  useEffect(() => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://test.payu.in/_payment";

    // Append form fields
    Object.keys(payload).forEach(key => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = payload[key];
      form.appendChild(input);
    });

    // Append the form to the document body and submit
    document.body.appendChild(form);
    form.submit();
  }, []);

  return <h1>Redirecting to Payment Gateway...</h1>;
};

export default PaymentComponent;
