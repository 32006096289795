import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import { lazy, Suspense, useLayoutEffect, React, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import AOS from "aos"
import Swiper from "swiper"
import Cookies from "js-cookie"
import Loader from "./components/Loader.jsx"

import "react-toastify/dist/ReactToastify.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "aos/dist/aos.css"
import "./App.css"
import HomePage from "./pages/HomePage.jsx"
import About from "./pages/About.jsx"
import Blog from "./pages/Blog.jsx"
import TermsAndConditions from "./pages/TermsAndConditions.jsx"
import PrivacyPolicy from "./pages/Privacy.jsx"
import ReturnRefund from "./pages/ReturnRefund.jsx"
import ShippingPolicy from "./pages/ShippingPolicy.jsx"
import PaymentComponent from "./pages/PayU.jsx"

const Layout = lazy(() => import("./pages/Layout.jsx"))
const Shop = lazy(() => import("./pages/Shop.jsx"))

const ProductDescription = lazy(() => import("./pages/ProductDescription"))
const ShippingAddress = lazy(() => import("./pages/ShippingAddress.jsx"))
const CartPage = lazy(() => import("./pages/CartPage.jsx"))
const PaymentRedirect = lazy(() => import("./pages/PaymentRedirect.jsx"))
const Orderhistory = lazy(() => import("./pages/Orderhistory.jsx"))
const Checkout = lazy(() => import("./pages/Checkout.jsx"))
const SignIn = lazy(() => import("./pages/SignIn.jsx"))
const Contact = lazy(() => import("./pages/Contact.jsx"))
const Home = lazy(() => import("./pages/Home.jsx"))

function App() {
  const token = Cookies.get("token")
  const userId = Cookies.get("userId")
  console.log("token :", token)

  const Wrapper = ({ children }) => {
    const location = useLocation()
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0)
    }, [location.pathname])
    return children
  }
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <div>
        <ToastContainer />
        <Router>
          <Wrapper>
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Routes>
                <Route
                  path="checkout"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          {" "}
                          <Loader />
                        </div>
                      }
                    >
                      <Checkout token={token} userId={userId} />
                    </Suspense>
                  }
                />
                <Route
                  path="signin"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          {" "}
                          <Loader />
                        </div>
                      }
                    >
                      <SignIn token={token} userId={userId} />
                    </Suspense>
                  }
                />
                <Route
                  path="/"
                  element={<Layout token={token} userId={userId} />}
                  errorElement={
                    <div>
                      {" "}
                      <Loader />
                    </div>
                  }
                >
                  <Route index element={<HomePage />} />
                  {/* <Route
                  path="home2"
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <Home />
                    </Suspense>
                  }
                /> */}
                  <Route
                    path="shop"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <Shop />
                      </Suspense>
                    }
                  />
                  <Route
                    path="product_details/:id"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <ProductDescription />
                      </Suspense>
                    }
                  />
                  <Route
                    path="cart"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <CartPage token={token} userId={userId} />
                      </Suspense>
                    }
                  />
                  {/* <Route
                  path="checkout"
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <Checkout />
                    </Suspense>
                  }
                /> */}
                  <Route
                    path="shippingaddress/:id"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <ShippingAddress token={token} userId={userId} />
                      </Suspense>
                    }
                  />

                  <Route
                    path="shippingaddress/:id/payment-redirect"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <PaymentRedirect />
                      </Suspense>
                    }
                  />
                  <Route
                    path="orderhistory/:id"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <Orderhistory token={token} userId={userId} />
                      </Suspense>
                    }
                  />
                  <Route
                    path="contact"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <Contact />
                      </Suspense>
                    }
                  />
                  <Route
                    path="about"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <About />
                      </Suspense>
                    }
                  />
                  <Route
                    path="blog"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <Blog />
                      </Suspense>
                    }
                  />
                  <Route
                    path="terms"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <TermsAndConditions />
                      </Suspense>
                    }
                  />
                  <Route
                    path="privacy"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <PrivacyPolicy />
                      </Suspense>
                    }
                  />
                  <Route
                    path="return-and-refund"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <ReturnRefund />
                      </Suspense>
                    }
                  />
                  <Route
                    path="shipping"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <ShippingPolicy />
                      </Suspense>
                    }
                  />
                  <Route
                    path="payu"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        <PaymentComponent />
                      </Suspense>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <Suspense
                        fallback={
                          <div>
                            {" "}
                            <Loader />
                          </div>
                        }
                      >
                        {/* <NotFound /> */}
                      </Suspense>
                    }
                  />
                </Route>
              </Routes>
            </Suspense>
          </Wrapper>
        </Router>
      </div>
    </>
  )
}

export default App
